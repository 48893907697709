@use "../../utils/variable" as *;

#works {
  width: 90%;
  margin: 100px auto;
  .works__title {
    display: flex;
    align-items: flex-end;
    font-family: "Simonetta", serif;
    margin-bottom: 75px;

    .line {
      width: 100%;
      height: 1px;
      background: $primary;
    }
  }

  .pokedex-pop-up {
    height: 100vh;
    width: 100%;
    background: rgba(0,0,0, .8);
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    .pop-up {
      width: 90%;
      height: 90%;
      background: $white;
      display: flex;
      justify-content: center;
      align-items: center;


      position: relative;

      .mark {
        position: absolute;
        top: 25px;
        right: 25px;
        cursor: pointer;
        i {
          font-size: 35px;
        }
      }

      iframe {
        width: 75%;
        height: 75%;
      }
    }
  }

  #works-swiper {
    position: relative;
    .swiper-next-button {
      position: absolute;
      top: 50%;
      transform: translateY(-120%);
      right: -5px;
    }



    .swiper-prev-button {
      position: absolute;
      top: 50%;
      transform: translateY(-120%);
      left: 0;
    }

    .swiper-slide {
      height: 475px;

      .slide-child {
        cursor: pointer;
        transition: .3s ease-in-out;
        height: 400px;
        position: relative;

        background: $faded-gradient;
        box-shadow:
                inset 5px 5px 2px rgba(0, 0, 0, 12%),
                inset -4px -4px 4px $white;

        .logo__github {
          position: absolute;
          bottom: 20px;
          left: 5px;
          width: 60px;
          z-index: 1000;
          transition: .3s ease-in-out;

          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }

    .swiper-pagination {

      span {
        background: $primary;
        border-radius: 0;
        height: 6px;
        width: 25px;
      }
    }

    .swiper-next-button, .swiper-prev-button {
      background: $faded-gradient;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      box-shadow:
              inset 5px 5px 2px rgba(0, 0, 0, 12%),
              inset -4px -4px 4px $white;

      &:hover {
        svg {
          transform: scale(1.1);
        }
      }
      svg {
        width: 20px;
        transition: .3s ease-in-out;

        &:hover {
          transform: scale(1.1);
        }
      }
    }

  }
}
