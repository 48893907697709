@use "../../../utils/_variable.scss" as *;

@mixin button-hover-color($color, $opacity: 10%) {
  &:hover {
    background: darken($color, $opacity);
  }
}

button {
  height: 50px;
  width: 200px;
  box-shadow:
          8px 7px 21px $light-gray,
          -6px -7px 11px $white;
  text-transform: uppercase;
  font-weight: 600;
  font-size: $sm;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: $xxs;

  &.btn-rounded {
    border-radius: 25px;
  }

  &.bg-primary { background: $primary-gradient;  @include button-hover-color($primary, 10%);}
  &.bg-none { background: $faded-gradient; @include button-hover-color($faded, 5%)}

  &.border-none { border: none; }
  &.border-primary { border: solid 2px $white; }

}

