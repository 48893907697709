#contact {
  width: 90%;
  margin: 0 auto;
}
#contact .contact__title {
  display: flex;
  align-items: flex-end;
  font-family: "Simonetta", serif;
  margin-bottom: 75px;
}
#contact .contact__title .line {
  width: 100%;
  height: 1px;
  background: #0275d8;
}
#contact .contact__wrapper {
  display: flex;
  gap: 5rem;
  background: linear-gradient(180deg, rgb(232, 234, 241) 0%, rgb(235, 236, 240) 100%);
  box-shadow: inset 5px 5px 2px rgba(0, 0, 0, 0.12), inset -4px -4px 4px #ffffff;
  padding: 3rem;
}
#contact .contact__wrapper > * {
  width: 50%;
}
#contact .contact__wrapper .contact__item {
  display: flex;
  flex-direction: column;
  gap: 5rem;
  font-family: "Quicksand", sans-serif;
}
#contact .contact__wrapper .contact__item h3 {
  font-size: 1.5rem;
  margin-bottom: 0.7rem;
}
#contact .contact__wrapper .contact__item p {
  font-size: 1rem;
}
#contact .contact__wrapper .contact__item .contact__pictogram {
  font-family: "Quicksand", sans-serif;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}
#contact .contact__wrapper .contact__item .contact__pictogram div {
  display: flex;
  align-items: center;
  gap: 1rem;
}
#contact .contact__wrapper .contact__item .contact__pictogram div a {
  text-decoration: none;
  color: #333333;
  font-size: 1rem;
}
#contact .contact__wrapper .contact__item .contact__pictogram div i {
  font-size: 2rem;
  color: #333333;
}
#contact .contact__wrapper .contact__form form {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
#contact .contact__wrapper .contact__form form input {
  width: 100%;
  font-family: "Quicksand", sans-serif;
  background: none;
  padding: 0.7rem;
  color: #151519;
  box-shadow: inset 5px 6px 3px rgba(0, 0, 0, 0.12), inset -1px -1px 1px #ffffff;
}
#contact .contact__wrapper .contact__form form input:focus {
  outline: none;
}
#contact .contact__wrapper .contact__form form .form__name {
  display: flex;
  gap: 2rem;
}
#contact .contact__wrapper .contact__form form .form__name div {
  width: 50%;
}
#contact .contact__wrapper .contact__form form .form__email {
  width: 100%;
}
#contact .contact__wrapper .contact__form form .form__message {
  width: 100%;
  box-shadow: inset 5px 6px 3px rgba(0, 0, 0, 0.12), inset -1px -1px 1px #ffffff;
}
#contact .contact__wrapper .contact__form form .form__message textarea {
  width: 100%;
  height: 150px;
  font-family: "Quicksand", sans-serif;
  background: none;
  border: none;
  padding: 1rem;
  color: #151519;
  resize: none;
}
#contact .contact__wrapper .contact__form form .form__message textarea:focus {
  outline: none;
}
#contact .contact__wrapper .contact__form form .alert-error {
  background: #f33c3c;
  border: solid 2px #ba0404;
  padding: 10px;
  border-radius: 12px;
  color: #ffffff;
  text-align: center;
}
#contact .contact__wrapper .contact__form form .alert-success {
  background: #23dc23;
  border: solid 2px #00a300;
  padding: 10px;
  border-radius: 12px;
  color: #ffffff;
  text-align: center;
}
#contact .contact__wrapper .contact__form form .alert-warning {
  background: #fab435;
  border: solid 2px #f3bf01;
  padding: 10px;
  border-radius: 12px;
  color: #ffffff;
  text-align: center;
}
#contact .contact__wrapper .contact__form form button[type=submit] {
  border: solid 1px #0275d8;
  color: #0275d8;
  font-family: "Quicksand", sans-serif;
  text-transform: uppercase;
  font-size: 1rem;
  width: 25%;
  height: 40px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}
#contact .contact__wrapper .contact__form form button[type=submit]:hover {
  color: #ffffff;
  background: #0275d8;
}

@media (max-width: 992px) {
  #contact .contact__wrapper {
    flex-wrap: wrap;
    justify-content: center;
  }
  #contact .contact__wrapper > * {
    width: 100%;
  }
}
@media (max-width: 640px) {
  #contact .contact__wrapper .contact__form form .form__name {
    flex-wrap: wrap;
  }
  #contact .contact__wrapper .contact__form form .form__name div {
    width: 100%;
  }
  #contact .contact__wrapper .contact__form form button[type=submit] {
    width: 100%;
  }
}

