.hero {
  position: relative;
  padding: 115px 50px 0 50px;
}
.hero .puddle {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
}
.hero .hero__content {
  padding: 50px 0 0 25px;
}
.hero .hero__content h1 {
  font-family: "Simonetta", serif;
  color: #0275d8;
}
.hero .hero__content .box {
  box-shadow: 0 20px 5px 5px rgba(0, 0, 0, 0.2);
  animation: blob 5s ease-in-out infinite;
  transition: all 1s ease-in-out;
}
@keyframes blob {
  0% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
  50% {
    border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
  }
  100% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
}
.hero .hero__content .name {
  position: relative;
  width: fit-content;
  font-family: "Kanit", serif;
  font-weight: bold;
  text-transform: uppercase;
  color: rgb(232, 234, 241);
  -webkit-text-stroke: 3px #0275d8;
}
.hero .hero__quote {
  background: blue;
  position: absolute;
  bottom: 130px;
  left: 50%;
  transform: translateX(-50%);
}

