$danger: #d9534f;
$primary: #0275d8;
$success: #5cb85c;
$warning: #f0ad4e;
$info: #5bc0de;

$black: #151519;
$white: #ffffff;
$faded: #eaeaea;
$light-gray: #898989;

$faded-gradient: linear-gradient(180deg, rgba(232,234,241,1) 0%, rgba(235,236,240,1) 100%);
$primary-gradient: linear-gradient(90deg, rgba(2,117,216,1) 0%, rgb(3, 90, 163) 100%);

$xxs: 8px;
$xs: 12px;
$sm: 14px;
$md: 18px;
$lg: 22px;

