@use "../../utils/variable" as *;

#experience {
  width: 90%;
  margin: 100px auto 0 auto;
  .experiences__title {
    display: flex;
    align-items: flex-end;
    font-family: "Simonetta", serif;
    margin-bottom: 75px;
    .line {
      width: 100%;
      height: 1px;
      background: $primary;
    }
  }

  .experience__wrapper {

    position: relative;
    width: 75%;
    margin: 0 auto;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;

      width: 2px;
      height: 100%;
      background: #151519;
    }


    .experience__item {
      background: $faded-gradient;
      display: flex;
      flex-direction: column;
      row-gap: 2rem;
      padding: 1rem;
      margin: 0 0 3rem 20px;
      width: 100%;
      box-shadow:
              inset 5px 5px 2px rgba(0, 0, 0, 12%),
              inset -4px -4px 4px $white;

      position: relative;

      @media (max-width: 640px) {
        width: 90%;
      }

      &::before {
        content: "";
        position: absolute;
        top: 20px;
        left: -20px;
        transform: translate(-50%, 50%);
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #151519;

      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 9px 9px 9px 0;
        border-color: transparent $faded transparent transparent;
        left: 0;
        top: 20px;
        margin-left: -9px;
        box-shadow:
                inset 5px 5px 2px rgba(0, 0, 0, 12%),
                inset -4px -4px 4px $white;
      }

      .experience__item__top {

        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        border-bottom: dashed 1px #333;
        padding-bottom: 2rem;
        h3 {
          text-transform: uppercase;
          font-size: 1.5rem ;
        }

        .information {
          display: flex;
          column-gap: 1rem;

          .company {
            font-weight: 600;
            text-transform: uppercase;
          }

          span {
            font-weight: 600;
          }
        }

        .experience__tag {
          background: $primary;
          height: 29px;
          width: fit-content;
          padding: 0 .8rem;
          font-weight: 600;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #ffffff;
          text-transform: capitalize;
        }
      }

      .experience__item__bottom {
        .description {
          font-family: "Playfair Display", sans-serif;
        }
      }
    }
  }
}

@media (max-width: 992px){
  #experience {
    .experience__wrapper {
      width: 100%;

      .experience__item {
        .experience__item__top {
          max-width: 100%;
          .information {
            display: flex;
            flex-direction: column;
            row-gap: 1rem;
            span {
              display: none;
            }
          }
        }
        .experience__item__bottom {
          .description {
            max-width: 100%;
          }
        }
      }
    }
  }
}