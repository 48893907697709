#skills {
  width: 90%;
  margin: 0 auto;
  padding-top: 100px;
}
#skills .skills__title {
  display: flex;
  align-items: flex-end;
  font-family: "Simonetta", serif;
  margin-bottom: 75px;
}
#skills .skills__title .line {
  width: 100%;
  height: 1px;
  background: #0275d8;
}
#skills .skills__wrapper {
  width: 100%;
  padding: 50px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 1fr);
  column-gap: 5rem;
  row-gap: 3rem;
  background: linear-gradient(180deg, rgb(232, 234, 241) 0%, rgb(235, 236, 240) 100%);
  box-shadow: inset 5px 5px 2px rgba(0, 0, 0, 0.12), inset -4px -4px 4px #ffffff;
}
#skills .skills__wrapper .skill__item p {
  margin-bottom: 10px;
  font-family: "Quicksand", sans-serif;
  font-size: 1rem;
  text-transform: uppercase;
}
#skills .skills__wrapper .skill__item.html p {
  color: #cc6699;
}
#skills .skills__wrapper .skill__item.html .progress__bar .progress__fill {
  width: 90%;
  background: #cc6699;
  border: solid 1px #cc6699;
}
#skills .skills__wrapper .skill__item.html .progress__bar .progress__fill::after {
  background: #cc6699;
}
#skills .skills__wrapper .skill__item.html .progress__bar .progress__fill::before {
  content: "90%";
  color: #cc6699;
}
#skills .skills__wrapper .skill__item.symfony p {
  color: #ec5453;
}
#skills .skills__wrapper .skill__item.symfony .progress__bar .progress__fill {
  width: 75%;
  background: #ec5453;
}
#skills .skills__wrapper .skill__item.symfony .progress__bar .progress__fill::after {
  background: #ec5453;
}
#skills .skills__wrapper .skill__item.symfony .progress__bar .progress__fill::before {
  content: "75%";
  color: #ec5453;
}
#skills .skills__wrapper .skill__item.node p {
  color: #68a063;
}
#skills .skills__wrapper .skill__item.node .progress__bar .progress__fill {
  width: 75%;
  background: #68a063;
}
#skills .skills__wrapper .skill__item.node .progress__bar .progress__fill::after {
  background: #68a063;
}
#skills .skills__wrapper .skill__item.node .progress__bar .progress__fill::before {
  content: "75%";
  color: #68a063;
}
#skills .skills__wrapper .skill__item.php p {
  color: #a84cb8;
}
#skills .skills__wrapper .skill__item.php .progress__bar .progress__fill {
  width: 90%;
  background: #a84cb8;
}
#skills .skills__wrapper .skill__item.php .progress__bar .progress__fill::after {
  background: #a84cb8;
}
#skills .skills__wrapper .skill__item.php .progress__bar .progress__fill::before {
  content: "90%";
  color: #a84cb8;
}
#skills .skills__wrapper .skill__item.javascript p {
  color: #f9bf3f;
}
#skills .skills__wrapper .skill__item.javascript .progress__bar .progress__fill {
  width: 90%;
  background: #f9bf3f;
}
#skills .skills__wrapper .skill__item.javascript .progress__bar .progress__fill::after {
  background: #f9bf3f;
}
#skills .skills__wrapper .skill__item.javascript .progress__bar .progress__fill::before {
  content: "90%";
  color: #f9bf3f;
}
#skills .skills__wrapper .skill__item.prestashop p {
  color: #2c98f0;
}
#skills .skills__wrapper .skill__item.prestashop .progress__bar .progress__fill {
  width: 90%;
  background: #2c98f0;
}
#skills .skills__wrapper .skill__item.prestashop .progress__bar .progress__fill::after {
  background: #2c98f0;
}
#skills .skills__wrapper .skill__item.prestashop .progress__bar .progress__fill::before {
  content: "90%";
  color: #2c98f0;
}
#skills .skills__wrapper .skill__item.docker p {
  color: #333333;
}
#skills .skills__wrapper .skill__item.docker .progress__bar .progress__fill {
  width: 75%;
  background: #333333;
}
#skills .skills__wrapper .skill__item.docker .progress__bar .progress__fill::after {
  background: #333333;
}
#skills .skills__wrapper .skill__item.docker .progress__bar .progress__fill::before {
  content: "75%";
  color: #333333;
}
#skills .skills__wrapper .skill__item.reactjs p {
  color: #4c768d;
}
#skills .skills__wrapper .skill__item.reactjs .progress__bar .progress__fill {
  width: 75%;
  background: #4c768d;
}
#skills .skills__wrapper .skill__item.reactjs .progress__bar .progress__fill::after {
  background: #4c768d;
}
#skills .skills__wrapper .skill__item.reactjs .progress__bar .progress__fill::before {
  content: "75%";
  color: #4c768d;
}
#skills .skills__wrapper .skill__item .progress__bar {
  height: 6px;
  width: 100%;
}
#skills .skills__wrapper .skill__item .progress__bar .progress__fill {
  background: royalblue;
  height: 100%;
  position: relative;
  border-radius: 8px;
}
#skills .skills__wrapper .skill__item .progress__bar .progress__fill::after {
  content: "";
  position: absolute;
  top: 50%;
  right: -5px;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: solid 1px #898989;
}
#skills .skills__wrapper .skill__item .progress__bar .progress__fill::before {
  position: absolute;
  top: -30px;
  right: 0;
  font-weight: 600;
  font-family: "Quicksand", sans-serif;
}
#skills .skills__wrapper .skill__item .progress__bar .progress__empty {
  background: #eaeaea;
  border: solid 1px #898989;
  height: 100%;
  width: 100%;
  border-radius: 8px;
}

@media (max-width: 860px) {
  #skills .skills__wrapper {
    display: flex;
    flex-direction: column;
    gap: 4rem;
  }
}

