@use "../../utils/variable" as *;

#contact {
  width: 90%;
  margin: 0 auto;
  .contact__title {
    display: flex;
    align-items: flex-end;
    font-family: "Simonetta", serif;
    margin-bottom: 75px;
    .line {
      width: 100%;
      height: 1px;
      background: $primary;
    }
  }
  .contact__wrapper {

    display: flex;
    gap: 5rem;
    background: $faded-gradient;
    box-shadow:
            inset 5px 5px 2px rgba(0, 0, 0, 12%),
            inset -4px -4px 4px $white;
    padding: 3rem;

    > * {
      width: 50%;
    }
    .contact__item {
      display: flex;
      flex-direction: column;
      gap: 5rem;

      font-family: "Quicksand", sans-serif;
      h3 {
        font-size: 1.5rem;
        margin-bottom: .7rem;
      }

      p {
        font-size: 1rem;
      }

      .contact__pictogram {

        font-family: "Quicksand", sans-serif;
        display: flex;
        flex-direction: column;
        gap: 2.5rem;

        div {
          display: flex;
          align-items: center;
          gap: 1rem;

          a {
            text-decoration: none;
            color: #333333;
            font-size: 1rem;

          }

          i {
            font-size: 2rem;
            color: #333333;
          }
        }
      }
    }

    .contact__form {
      form {
        display: flex;
        flex-direction: column;
        gap: 3rem;


        input {
          width: 100%;
          font-family: "Quicksand", sans-serif;
          background: none;
          padding: .7rem;
          color: #151519;
          box-shadow:
                  inset 5px 6px 3px rgba(0, 0, 0, 12%),
                  inset -1px -1px 1px $white;

          &:focus {
            outline: none;
          }
        }
        .form__name {
          display: flex;
          gap: 2rem;

          div {
            width: 50%;
          }
        }

        .form__email {
          width: 100%;
        }

        .form__message {
          width: 100%;
          box-shadow:
                  inset 5px 6px 3px rgba(0, 0, 0, 12%),
                  inset -1px -1px 1px $white;
          textarea {
            width: 100%;
            height: 150px;
            font-family: "Quicksand", sans-serif;
            background: none;
            border: none;
            padding: 1rem;
            color: #151519;
            resize: none;

            &:focus {
              outline: none;
            }

          }
        }

        .alert-error {
          background: #f33c3c;
          border: solid 2px #ba0404;
          padding: 10px;
          border-radius: 12px;
          color: $white;
          text-align: center;
        }

        .alert-success {
          background: #23dc23;
          border: solid 2px #00a300;
          padding: 10px;
          border-radius: 12px;
          color: $white;
          text-align: center;
        }

        .alert-warning {
          background: #fab435;
          border: solid 2px #f3bf01;
          padding: 10px;
          border-radius: 12px;
          color: $white;
          text-align: center;
        }


        button[type="submit"] {
          border: solid 1px $primary;
          color: $primary;
          font-family: "Quicksand", sans-serif;
          text-transform: uppercase;
          font-size:  1rem;
          width: 25%;
          height: 40px;
          cursor: pointer;
          transition: .2s ease-in-out;

          &:hover {
            color: #ffffff;
            background: $primary;
          }

        }
      }
    }
  }
}

@media (max-width: 992px){
  #contact {
    .contact__wrapper {
      flex-wrap: wrap;
      justify-content: center;

      > * {
        width: 100%;
      }
    }
  }
}

@media (max-width: 640px){
  #contact {

    .contact__wrapper {

      .contact__form {
        form {
          .form__name {
            flex-wrap: wrap;

            div {
              width: 100%;
            }
          }

          button[type="submit"] {
            width: 100%;

          }
        }

      }
    }
  }
}