@use "../../../utils/variable" as *;

header {

  position: fixed;
  height: 80px;
  top: 25px;
  left: 50%;
  z-index: 1000;
  transform: translateX(-50%);

  background: $faded-gradient;
  box-shadow:
          8px 7px 21px $light-gray,
          -6px -7px 11px $white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 50px;
  border-radius: 50px;
  border: solid 2px $white;

  .switch-menu {
    position: absolute;
    right: 50px;
    top: 50%;
    transform: translateY(-50%);
  }
  div {
    display: flex;
  }
  nav {
    ul {
     display: flex;
     gap: $md;
     li {
       list-style: none;
       padding: $xs $lg;
       border-radius: 29px;

       &.active {
         box-shadow:
                 inset 5px 5px 2px rgba(0, 0, 0, 12%),
                 inset -4px -4px 4px $white;
       }
     }
   }
 }
}

@media (max-width: 1024px) {
  header {
    .menu-transition {
      overflow: hidden;
      transition: max-height .3s ease-in-out;
    }

    .menu-hidden {
      max-height: 0;
    }

    .menu-visible {
      max-height: 90vh;
    }

    .menu-toggle {
      position: absolute;
      right: 2.5rem;
      top: 50%;
      transform: translate(0, -50%);
      height: 26px;
      width: 29px;

      &, &:hover {
        color: #000;
      }
    }

    .menu-toggle-bar {
      display: block;
      position: absolute;
      top: 50%;
      margin-top: -1px;
      right: 0;
      width: 100%;
      height: 2px;
      border-radius: 4px;
      background-color: black;
      transition: all 0.3s ease;

      &.menu-toggle-bar--top {
        transform: translate(0, -8px);
      }
      &.menu-toggle-bar--middle {
      }
      &.menu-toggle-bar--bottom {
        transform: translate(0, 8px);
      }

    }
    .nav-open{
      .menu-toggle-bar--top {
        transform: translate(0, 0) rotate(45deg);
      }
      .menu-toggle-bar--middle {
        opacity: 0;
      }
      .menu-toggle-bar--bottom {
        transform: translate(0, 0) rotate(-45deg);
      }
    }
    nav {
      position: absolute;
      top: 80px;
      right: 40px;
      width: 90%;
      transition: .3s ease-in-out;
      background: $faded-gradient;
      ul {
        flex-direction: column;
        padding: $sm;
        height: 90vh;

        li {
          width: fit-content;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  header {
    nav {
      right: 20px;
    }
  }
}