@use "../../../utils/variable" as *;

.hero {
  position: relative;
  padding: 115px 50px 0 50px;


  .puddle {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
  }

  .hero__content {
    padding: 50px 0 0 25px;

    h1 {
      font-family: 'Simonetta', Poppins, sans-serif;
      color: $primary;
    }

    .box {
      box-shadow: 0 20px 5px 5px rgba(0, 0, 0, .2);
      animation: blob 5s ease-in-out infinite;
      transition: all 1s ease-in-out;
    }

    @keyframes  blob {
      0% { border-radius: 60% 40% 30% 70%/60% 30% 70% 40%; }

      50% { border-radius: 30% 60% 70% 40%/50% 60% 30% 60%; }

      100% { border-radius: 60% 40% 30% 70%/60% 30% 70% 40%; }
    }

    .name {
      position: relative;
      width: fit-content;
      font-family: "Kanit", serif;
      font-weight: bold;
      text-transform: uppercase;
      color: rgba(232,234,241,1);
      -webkit-text-stroke: 3px $primary;
      //&::after {
      //  content: '';
      //  position: absolute;
      //  top: 50%;
      //  transform: translateY(-50%);
      //  left: 0;
      //  z-index: -1;
      //  height: 50%;
      //  width: 100%;
      //  background: lightseagreen;
      //}
    }
  }



  .hero__quote {
    background: blue;
    position: absolute;
    bottom: 130px;
    left: 50%;
    transform: translateX(-50%);

  }
}