@import url('https://fonts.googleapis.com/css2?family=Simonetta:ital@1&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Kanit';
    src: url('../public/assets/fonts/kanit-bold.woff') format('woff'),
    url('../public/assets/fonts/kanit-semibold.woff') format('woff');
}
