#experience {
  width: 90%;
  margin: 100px auto 0 auto;
}
#experience .experiences__title {
  display: flex;
  align-items: flex-end;
  font-family: "Simonetta", serif;
  margin-bottom: 75px;
}
#experience .experiences__title .line {
  width: 100%;
  height: 1px;
  background: #0275d8;
}
#experience .experience__wrapper {
  position: relative;
  width: 75%;
  margin: 0 auto;
}
#experience .experience__wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 100%;
  background: #151519;
}
#experience .experience__wrapper .experience__item {
  background: linear-gradient(180deg, rgb(232, 234, 241) 0%, rgb(235, 236, 240) 100%);
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  padding: 1rem;
  margin: 0 0 3rem 20px;
  width: 100%;
  box-shadow: inset 5px 5px 2px rgba(0, 0, 0, 0.12), inset -4px -4px 4px #ffffff;
  position: relative;
}
@media (max-width: 640px) {
  #experience .experience__wrapper .experience__item {
    width: 90%;
  }
}
#experience .experience__wrapper .experience__item::before {
  content: "";
  position: absolute;
  top: 20px;
  left: -20px;
  transform: translate(-50%, 50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #151519;
}
#experience .experience__wrapper .experience__item::after {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 9px 9px 9px 0;
  border-color: transparent #eaeaea transparent transparent;
  left: 0;
  top: 20px;
  margin-left: -9px;
  box-shadow: inset 5px 5px 2px rgba(0, 0, 0, 0.12), inset -4px -4px 4px #ffffff;
}
#experience .experience__wrapper .experience__item .experience__item__top {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  border-bottom: dashed 1px #333;
  padding-bottom: 2rem;
}
#experience .experience__wrapper .experience__item .experience__item__top h3 {
  text-transform: uppercase;
  font-size: 1.5rem;
}
#experience .experience__wrapper .experience__item .experience__item__top .information {
  display: flex;
  column-gap: 1rem;
}
#experience .experience__wrapper .experience__item .experience__item__top .information .company {
  font-weight: 600;
  text-transform: uppercase;
}
#experience .experience__wrapper .experience__item .experience__item__top .information span {
  font-weight: 600;
}
#experience .experience__wrapper .experience__item .experience__item__top .experience__tag {
  background: #0275d8;
  height: 29px;
  width: fit-content;
  padding: 0 0.8rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  text-transform: capitalize;
}
#experience .experience__wrapper .experience__item .experience__item__bottom .description {
  font-family: "Playfair Display", sans-serif;
}

@media (max-width: 992px) {
  #experience .experience__wrapper {
    width: 100%;
  }
  #experience .experience__wrapper .experience__item .experience__item__top {
    max-width: 100%;
  }
  #experience .experience__wrapper .experience__item .experience__item__top .information {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }
  #experience .experience__wrapper .experience__item .experience__item__top .information span {
    display: none;
  }
  #experience .experience__wrapper .experience__item .experience__item__bottom .description {
    max-width: 100%;
  }
}

