header {
  position: fixed;
  height: 80px;
  top: 25px;
  left: 50%;
  z-index: 1000;
  transform: translateX(-50%);
  background: linear-gradient(180deg, rgb(232, 234, 241) 0%, rgb(235, 236, 240) 100%);
  box-shadow: 8px 7px 21px #898989, -6px -7px 11px #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 50px;
  border-radius: 50px;
  border: solid 2px #ffffff;
}
header .switch-menu {
  position: absolute;
  right: 50px;
  top: 50%;
  transform: translateY(-50%);
}
header div {
  display: flex;
}
header nav ul {
  display: flex;
  gap: 18px;
}
header nav ul li {
  list-style: none;
  padding: 12px 22px;
  border-radius: 29px;
}
header nav ul li.active {
  box-shadow: inset 5px 5px 2px rgba(0, 0, 0, 0.12), inset -4px -4px 4px #ffffff;
}

@media (max-width: 1024px) {
  header .menu-transition {
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
  }
  header .menu-hidden {
    max-height: 0;
  }
  header .menu-visible {
    max-height: 90vh;
  }
  header .menu-toggle {
    position: absolute;
    right: 2.5rem;
    top: 50%;
    transform: translate(0, -50%);
    height: 26px;
    width: 29px;
  }
  header .menu-toggle, header .menu-toggle:hover {
    color: #000;
  }
  header .menu-toggle-bar {
    display: block;
    position: absolute;
    top: 50%;
    margin-top: -1px;
    right: 0;
    width: 100%;
    height: 2px;
    border-radius: 4px;
    background-color: black;
    transition: all 0.3s ease;
  }
  header .menu-toggle-bar.menu-toggle-bar--top {
    transform: translate(0, -8px);
  }
  header .menu-toggle-bar.menu-toggle-bar--bottom {
    transform: translate(0, 8px);
  }
  header .nav-open .menu-toggle-bar--top {
    transform: translate(0, 0) rotate(45deg);
  }
  header .nav-open .menu-toggle-bar--middle {
    opacity: 0;
  }
  header .nav-open .menu-toggle-bar--bottom {
    transform: translate(0, 0) rotate(-45deg);
  }
  header nav {
    position: absolute;
    top: 80px;
    right: 40px;
    width: 90%;
    transition: 0.3s ease-in-out;
    background: linear-gradient(180deg, rgb(232, 234, 241) 0%, rgb(235, 236, 240) 100%);
  }
  header nav ul {
    flex-direction: column;
    padding: 14px;
    height: 90vh;
  }
  header nav ul li {
    width: fit-content;
  }
}
@media (max-width: 1024px) {
  header nav {
    right: 20px;
  }
}

